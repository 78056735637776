<template lang="pug">
div(
  v-loading='isLoading',
  element-loading-text='Загрузка данных...',
)
  .withdraw-error
    span(v-if="isError") {{ errorText }}
  .block-wrap
    input.w-100(
      @input="checkWallet",
      v-model="address"
      placeholder="Адрес для получения BTC",
      type="text",
    )
  template(v-if="isGlobalAutoMode")
    .withdraw-error(v-if="isMinBalanceError")
      span {{ minBalanceError }}
    .block__min-balance
      input(
        @input="checkMinBalance()"
        v-model="min_balance"
        placeholder="Минимальный остаток",
        type="number",
      )
  template(v-else-if="isLocalWallet")
    textarea.withdraw-comment__textarea(
      v-model="comment",
      placeholder='Напишите комментарий',
      maxlength='255',
    )
    .withdraw-comment__length {{ commentLength }}
</template>

<script>
import WAValidator from "wallet-validator";
import {USER_WALLET_VALIDATE} from "@/store/actions/user";
import {mapGetters} from "vuex";

export default {
  name: "TheWithdrawAddress",
  props: {
    cryptoId: {
      type: Number,
      required: true,
    },
    cryptoName: {
      type: String,
      default: 'bitcoin',
      required: true
    },
    reset: {
      type: Boolean,
      default: false,
      required: true,
    },
    isGlobalAutoMode: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      address: '',
      errorText: '',
      isError: false,
      isLocalWallet: false,
      comment: '',
      min_balance: null,
      isMinBalanceError: false,
      minBalanceError: "",
    };
  },
  computed: {
    ...mapGetters([
      'loading'
    ]),
    isLoading() {
      return this.loading(USER_WALLET_VALIDATE) === 'loading'
    },
    commentLength() {
      return `${this.comment.length} / 255`
    },
  },
  watch: {
    address(val) {
      if (val) {
        this.withdrawCheck(val)
      } else {
        this.isLocalWallet = false;
        this.$emit('withdraw-address', {
          localWallet: this.isLocalWallet
        })
      }
    },
    min_balance(val) {
      this.$emit('min-balance', val)
    },
    comment(val) {
      this.$emit('withdraw-comment', val)
    },
    reset(val) {
      if (val) {
        this.address = ''
        this.errorText = ''
        this.isError = false
        this.isLocalWallet = false
        this.comment = ''
        this.min_balance = null
        this.$emit('withdraw-address', {
          address: this.address,
          localWallet: this.isLocalWallet,
          error: this.isError
        })
      }
    }
  },
  methods: {
    checkWallet() {
      let network = process.env.NODE_ENV === 'production' ? 'prod' : 'testnet';
      if (!WAValidator.validate(this.address, this.cryptoName, network)) {
        this.isError = true;
        this.errorText = 'Недопустимый формат адреса';
        this.$emit('withdraw-address', {
          error: true
        })
      } else {
        this.isError = false;
        this.errorText = '';
      }
    },
    checkMinBalance() {
      if(this.min_balance.length > 9) {
        this.min_balance = Number(this.min_balance.substring(0, 9))
      }

      const num = Number(this.min_balance)
      if (Math.round(num) === num) return;
      if (Math.round(num) !== num) return;
      this.isMinBalanceError = true
      this.minBalanceError = 'Не верный минимальный остаток'
    },
    async withdrawCheck(withdraw) {
      if (!this.isError) {
        const {data} = await this.$store.dispatch(USER_WALLET_VALIDATE, {
          address: withdraw,
          crypto_currency_id: this.cryptoId
        })
        this.isLocalWallet = data ? data.result : false;
        this.$emit('withdraw-address', {
          address: this.address,
          localWallet: this.isLocalWallet,
          error: this.isError,
        })
      }
    },
  }
}
</script>

<style scoped>
.withdraw-comment__textarea {
  background: #ffffff;
  border: 1px solid $blue300;
  width: 100%;
  border-radius: 6px;
  min-height: 80px;
  max-height: 200px;
  padding: 10px 20px;
  margin-top: 10px;
}
.withdraw-comment__length{
  text-align: right;
  font-size: 12px;
  color: #929292;
}
</style>
