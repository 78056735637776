<template lang="pug">
  .operation-block-history
    .operation-block-history__block.operation-block-history__data
      span.operation-history__date {{ divideDate(record.created_at).dateDay }}
      span.operation-history__date {{ divideDate(record.created_at).time }}
    .operation-block-history__block.operation-block-history__amount
      p Сумма
      span(:class="{'amount-plus': record.amount[0] === '+', 'amount-minus': record.amount[0] === '-'}") {{ record.amount }}
    .operation-block-history__block.operation-block-history__amount
      p Комиссия
      span {{ record.commission }}
    .operation-block-history__block.operation-block-history__amount
      p Статус
      span {{ record.status }}
    .operation-block-history__block.operation-block-history__amount
      p Комментарий
      template(v-if="record.comment")
        el-dialog(
          :visible.sync="dialogVisible"
          title="Комментарий"
          width="30%"
        )
          span {{ record.comment }}
        span
          i.el-icon-chat-line-square.open-comment(title="Просмотреть комментарий" @click="dialogVisible = true")
      template(v-else)
        span Отсутствует
    .operation-block-history__block.operation-block-history__description
      p Кошелек
      a(:href="`https://blockchair.com/bitcoin/address/${record.wallet}/`", target="_blank") {{ record.wallet }}
</template>
<script>
  import utils from '@/components/mixins/utils';

  export default {
    mixins: [
      utils,
    ],
    props: {
      record: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        dialogVisible: false
      };
    },
  }
</script>
<style lang="scss" scoped>
  .operation-block-history__data {
    max-width: 150px;
  }
  .open-comment {
    font-size: 24px;
    cursor: pointer;
  }
  .open-comment:hover {
    color: #0D9F45;
  }
</style>
