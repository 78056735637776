<template lang="pug">
div
  .operations-tbc_conclusion__block
    .withdraw-fiat
      p(v-if="!isGlobalAutoMode") Выводимая сумма в валюте:
        span &nbsp;{{ showInputBalanceIn.amount }} {{ showInputBalanceIn.code }}
  .operations-tbc_conclusion__block
    .green-input
      the-mask.form-control(
        @input="checkAmount",
        :placeholder="balancePlaceholder",
        v-model="withdrawAmount"
        :mask="'X.XXXXXXXX'",
        :masked="true",
      )
      span.cursor-link(v-if="isInputMaxAmountShow && !isGlobalAutoMode" @click="inputMaxAmount") вывести все
    .operations-tbc_conclusion__block-btn
      slot(name="actions")
  .operations-tbc_conclusion__block
    div(v-if="withdrawMode !== 'free'")
      .operations-tbc_conclusion__block-text.checkbox-block(v-if="commissionValue !== 0")
        input#check1.check(type='checkbox', name='check', v-model='commissionIncluded')
        label.check-label(for='check1')
          span.operations-tbc_conclusion__checkbox Списать комиссию с суммы вывода
  .operations-tbc_conclusion__block(v-if="minAmount !== 0")
    .operations-tbc_conclusion__block-text
      p Минимальная сумма вывода: {{ minAmountValue }}
  .operations-tbc_conclusion__block

  .withdraw-error
    p(v-if="isAmountError") {{ amountError }}
</template>

<script>
import {mapGetters} from "vuex";
import utils from '@/components/mixins/utils';
import {USER_WALLET_COMISSIONS} from "@/store/actions/user";

export default {
  name: "TheWithdrawAmount",
  mixins: [utils],
  props: {
    isGlobalAutoMode: {
      type: Boolean,
      default: false,
      required: true
    },
    withdrawMode: {
      type: String,
      required: true
    },
    cryptoCode: {
      type: String,
      default: 'btc',
      required: true
    },
    isLocalWallet: {
      type: Boolean,
      default: false,
      required: true
    },
    cryptoId: {
      type: Number,
      required: true,
    },
    reset: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      withdrawAmount: null,
      commissionIncluded: false,
      isAmountError: false,
      amountError: '',
      commission_fast: 0.00075,
      commission_free: 0,
      commission_standard: 0,
      min_amount_standard: 0,
      min_amount_fast: 0.000175,
      min_amount_free: 0.0001,
      maxAmount: false
    }
  },
  computed: {
    ...mapGetters([
      'getBalance',
      'getSelectedCurrency',
      'getPriceTickers',
      'getUserWalletCommissions',
      'getCommissionsCodes',
    ]),
    minAmount() {
      if (this.isLocalWallet) return 0;
      let amount;
      if (this.withdrawMode === 'fast') {
        amount = this.min_amount_fast;
      } else if (this.withdrawMode === 'normal') {
        amount = this.min_amount_standard;
      } else {
        amount = this.min_amount_free
      }
      if (this.commissionBTC > amount) {
        return this.commissionBTC;
      }
      return amount
    },
    balanceCommissionsIncluded() {
      return (this.commissionIncluded === true || this.isLocalWallet || this.isGlobalAutoMode) ? this.convertStringToNumber(this.getBalance.amount) : this.floatPlus(this.getBalance.amount, -this.commissionBTC);
    },
    commissionBTC() {
      if (this.withdrawMode === 'fast') return this.commission_fast
      if (this.withdrawMode === 'normal') return this.commission_standard
      return this.commission_free
    },
    commissionUSD() {
      if (this.withdrawMode === 'fast') return this.cryptoPrice * this.commission_fast
      if (this.withdrawMode === 'normal') return this.cryptoPrice * this.commission_standard
      return this.cryptoPrice * this.commission_free
    },
    balancePlaceholder() {
      if (this.balanceCommissionsIncluded < 0) return 0;
      return this.getBalance ? this.balanceCommissionsIncluded : 'Введите количество';
    },
    commissionValue() {
      if (this.isLocalWallet) return 0
      const btc = `${this.commissionBTC} ${this.cryptoCode.toUpperCase()}`;
      const usd = this.commissionUSD ? ` (${parseFloat(this.commissionUSD).toFixed(3)} USD)` : '';
      return btc + usd;
    },
    minAmountValue() {
      return `${this.minAmount.toFixed(8) * 1} ${this.cryptoCode.toUpperCase()}`;
    },
    cryptoPrice() {
      return this.getPriceTickers[`${this.cryptoCode}usd`]
        ? this.getPriceTickers[`${this.cryptoCode}usd`].price
        : null;
    },
    isInputMaxAmountShow() {
      if (this.isLocalWallet) return true;
      return this.getBalance && this.getBalance.amount > this.commissionBTC;
    },
    showInputBalanceIn() {
      let rate = this.getBalance.fiat.rates.find(item => {
        return item.code === this.getSelectedCurrency;
      });
      return {
        amount: (this.withdrawAmount * this.cryptoPrice * rate.rate).toFixed(0),
        code: rate.code.toUpperCase()
      };
    },
  },
  watch: {
    getUserWalletCommissions(val) {
      if (val.pay_out.btc) {
        this.setCommissions(val['pay_out'][this.cryptoCode])
      } else {
        this.setCommissions(val.pay_out)
      }
    },
    commissionIncluded(val) {
      if (val) {
        this.$confirm(`Комиссия будет списана с суммы вывода, в итоге на кошелек будет зачислено меньше на ${this.commissionBTC} ${this.cryptoCode.toUpperCase()}. Согласны?`, 'Внимание!', {
          confirmButtonText: 'Согласен',
          cancelButtonText: 'Отмена',
        }).catch(() => {
          this.commissionIncluded = false;
        });
      }
    },
    withdrawMode(val) {
      if (this.maxAmount) {
        this.inputMaxAmount()
      }
      this.$emit('withdraw-amount', {
        amount: this.withdrawAmount,
        commission: this.commissionValue,
        error: this.isAmountError,
        included: this.commissionIncluded
      })
      if (val === 'free') {
        this.commissionIncluded = false;
      }
    },
    isGlobalAutoMode(val) {
      if (val) {
        this.withdrawAmount = ''
        this.amountError = ''
        this.maxAmount = false
        this.$emit('withdraw-amount', {amount: this.withdrawAmount, commission: this.commissionValue})
      }
    },
    isLocalWallet(val) {
      if (val === true) {
        this.$emit('withdraw-amount', {
          amount: this.withdrawAmount,
          commission: this.commissionValue,
          error: this.isAmountError,
          included: this.commissionIncluded
        })
      }
    },
    reset(val) {
      if (val) {
        this.withdrawAmount = null
        this.commissionIncluded = false
        this.isAmountError = false
        this.$emit('withdraw-amount', {
          amount: this.withdrawAmount,
          commission: this.commissionValue,
          error: this.isAmountError,
          included: this.commissionIncluded
        })
      }
    },
    minAmount() {
      this.checkAmount();
    },
  },
  mounted() {
    this.$store.dispatch(USER_WALLET_COMISSIONS).then(resp => {
      this.setCommissions(resp.data['pay_out'][this.cryptoCode]);
    });
    setTimeout(() => {
      this.$emit('withdraw-amount', {commission: this.commissionValue})
    }, 500)
  },
  methods: {
    checkAmount() {
      if (!this.withdrawAmount) {
        this.maxAmount = false
        this.isAmountError = false;
        this.amountError = '';
        this.$emit('withdraw-amount', {
          amount: this.withdrawAmount,
          commission: this.commissionValue,
          error: true,
          included: this.commissionIncluded
        })
        return;
      }

      let balance = +this.balanceCommissionsIncluded;
      let amount = +this.withdrawAmount;

      if (this.withdrawAmount[0] === '+' || this.withdrawAmount[0] === '-' || this.withdrawAmount[0] === '.') {
        this.isAmountError = true;
        this.$emit('withdraw-amount', {error: true})
        this.amountError = 'Вводимое значение не является числом';
        return;
      }

      if (isNaN(amount)) {
        this.isAmountError = true;
        this.$emit('withdraw-amount', {error: true})
        this.amountError = 'Вводимое значение не является числом';
        return;
      }

      if (amount > balance && !this.isGlobalAutoMode) {
        this.isAmountError = true;
        this.$emit('withdraw-amount', {error: true})
        this.amountError = 'Введенная сумма превышает ваш баланс';
        return;
      }

      if (amount < this.minAmount) {
        this.isAmountError = true;
        this.$emit('withdraw-amount', {error: true})
        this.amountError = 'Вводимая сумма меньше минимальной';
        return;
      }

      if (amount === 0) {
        this.isAmountError = true;
        this.$emit('withdraw-amount', {error: true});
        this.amountError = 'Вводимая сумма должна быть больше 0';
        return;
      }

      this.isAmountError = false;
      this.$emit('withdraw-amount', {
        amount: this.withdrawAmount,
        commission: this.commissionValue,
        error: false,
        included: this.commissionIncluded,
      });
    },
    inputMaxAmount() {
      this.withdrawAmount = parseFloat(this.balanceCommissionsIncluded).toFixed(8);
      this.maxAmount = true
      this.checkAmount();
    },
    setCommissions(commissions) {
      this.min_amount_free = this.findCommission(commissions, 'free', 'min_amount');
      this.commission_free = this.findCommission(commissions, 'free', 'commission');
      this.min_amount_standard = this.findCommission(commissions, 'standard', 'min_amount');
      this.commission_standard = this.findCommission(commissions, 'standard', 'commission');
      this.min_amount_fast = this.findCommission(commissions, 'fast', 'min_amount');
      this.commission_fast = this.findCommission(commissions, 'fast', 'commission');
    },
    findCommission(commissions, speed, type) {
      return commissions.find(comm => comm.code === this.getCommissionsCodes[speed])[type];
    },
  }
}
</script>

<style scoped>

</style>
