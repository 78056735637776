<template lang="pug">
  .operation-block-history
    .operation-block-history__block.operation-block-history__data
      span.operation-history__date {{ divideDate(record.created_at).dateDay }}
      span.operation-history__date {{ divideDate(record.created_at).time }}
    .operation-block-history__block.operation-block-history__description
      p Сумма
      span(:class="{'amount-plus': record.amount[0] === '+', 'amount-minus': record.amount[0] === '-'}") {{ record.amount }}
    .operation-block-history__block.operation-block-history__description
      p Комиссия
      span {{ getCommission }}
    .operation-block-history__block.operation-block-history__description
      p Статус
      span {{ record.status }}
    .operation-block-history__block.operation-block-history__description
      p Комментарий
      .operation-block-history__comment(v-if="record.comment")
        span.comment(@click="dialogVisible = true") {{ comment(record.comment)}}&nbsp;&nbsp;
        i(class='el-icon-view eye-icon' @click="dialogVisible = true")
        el-dialog(
          :visible.sync="dialogVisible"
          title="Комментарий"
          width="30%"
        )
          span  {{ record.comment }}
      span(v-else) Отсутствует
    .operation-block-history__block.operation-block-history__description
      p Криптовалюта
      span {{ record.crypto.label }}
</template>
<script>
  import utils from '@/components/mixins/utils';

  export default {
    mixins: [
      utils,
    ],
    props: {
      record: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        dialogVisible: false
      };
    },
    computed:{
      getCommission(){
        return this.record.commission || 0;
      },
    },
    methods: {
      comment(val) {
        if (val.length > 15) {
          return val.slice(0, 15) + '...'
        } else return val
      },
    }
  }
</script>
<style lang="scss" scoped>
  .operation-block-history__data {
    max-width: 150px;
  }
  .comment {
    text-align: center;
    width: 16ch;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
  .operation-block-history__comment {
    &:hover .el-icon-camera,
    &:hover .eye-icon{
      color: $blue600;
    }
    &:hover {
      cursor: pointer;
    }
  }
</style>
