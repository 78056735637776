<template lang="pug">
  .tabs
    input#tab3(:disabled="isLoading").tab3(type='radio', name='tabs', :checked="checked === 'tab3'" @click="selectTab('USER_WALLET_HISTORY')")
    label(for='tab3', title='Просмотр истории кошелька' :class="{'tab-disabled': isLoading}") История
    input#tab1(:disabled="isLoading").tab1(type='radio', name='tabs', :checked="checked === 'tab1'" @click="selectTab('USER_WALLET_PAYING')")
    label(for='tab1', title='Истории пополнений кошелька' :class="{'tab-disabled': isLoading}") Пополнения
    input#tab2(:disabled="isLoading").tab2(type='radio', name='tabs', :checked="checked === 'tab2'" @click="selectTab('USER_WALLET_PAYOUT')")
    label(for='tab2', title='История выводов с кошелька' :class="{'tab-disabled': isLoading}") Выводы
</template>

<script>
export default {
  name: 'HistoryTabs',
  props: {
    checked: {
      type: String,
      default: 'tab3'
    },
    isLoading: Boolean
  },
  methods:{
    selectTab(component){
      if (!this.isLoading) {
        this.$emit('selectComponent', component);
      }
    }
  }
};
</script>

<style scoped>

</style>
