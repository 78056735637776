<template lang="pug">
  main.main-content.layout-main__centering.container.pb-5
    div
      Balance
      TransactionHistory(v-if="currentContent === 'default'")
      Deposit(v-if="currentContent === 'deposit'")
      Withdraw(v-if="currentContent === 'withdraw'")
</template>
<script>
import Balance from '../components/Wallet/Balance';
import Deposit from '../components/Wallet/Deposit';
import Withdraw from '../components/Wallet/Withdraw/index';
import TransactionHistory from '../components/Wallet/TransactionHistory';
import analytics from '../components/mixins/analytics';

export default {
  components: {
    TransactionHistory,
    Balance,
    Deposit,
    Withdraw
  },
  mixins: [analytics],
  data() {
    return {};
  },
  computed: {
    currentContent() {
      return this.$route.meta.content ? this.$route.meta.content : 'default';
    }
  },
  mounted() {
    this.sendAnalytics('Wallet', 'wallet_open', false);
  }
};

</script>
<style>

</style>
