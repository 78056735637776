<template lang="pug">
  .row(
    v-loading='isLoading',
    element-loading-text='Загрузка данных...',
  )
    .col-12
      .kyc-no-confirmed-message.operations-tbc.operations-tbc_replenishment(
        v-if="mustGoThroughKyc"
        v-loading="isProfileLoading"
      )
        KycNoVerificationMessage
      .profile-container.wrapper_gray-border.operations-tbc.operations-tbc_replenishment(
        v-else
      )
        h3.operations-tbc__title Введите адрес пополнения BTC
        p.operations-tbc_replenishment__subject
          | Отправьте BTC на адрес кошелька приведенного ниже. Депозит будет начислен на соответствующий кошелек.
        el-alert.operations-tbc_replenishment__aml-notice(
          type="warning"
          :closable="false"
        )
          | В случае пополнения аккаунта биткоином, который не пройдет проверку AML, будут затребованы ваши документы для верификации.
        .operations-tbc_replenishment__container
          .operations-tbc_replenishment__input-block
            .d-e-blocks.width-block.take-set-input.take-set-input_no-right-block.pr-0.input-white.w-100
              .wallet-info
                input(type='text', :value="address", :readonly="true")
            button.btn.btn__main.w-100.mt-2(@click="copyToClipboard(address)" type='button') Скопировать
          span.operations-tbc_replenishment__text или QR-код:
          img.operations-tbc_replenishment__img(:src='qr', alt='')
        .operations-tbc_replenishment__list
          ul
            li(v-for="rule in getRules") {{ rule }}
</template>
<script>
import QRCode from 'qrcode';
import { USER_WALLET_ADDRESS } from '../../store/actions/user';
import { USER_PROFILE } from '../../store/actions/user';
import { mapGetters } from 'vuex';
import analytics from '../../components/mixins/analytics';
import KycNoVerificationMessage from '../../components/AwesomeComponents/Account/Profile/KycNoVerificationMessage.vue';

export default {
  components: {
    KycNoVerificationMessage,
  },

  mixins: [analytics],

  props: {
    cryptoCode: {
      type: String,
      default: 'btc'
    }
  },

  data() {
    return {
      address: '',
      qr: '',
      // todo hardcoded on backend also
      comissions: {
        commission: '0',
        min_amount: '0.0001'
      }
    };
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'getProfile',
      userWalletAddress: 'getUserWalletAddress',
    }),
    isProfileLoading() {
      return this.loading(USER_PROFILE) === 'loading';
    },
    mustGoThroughKyc() {
      return this.profile.is_kyc_required && !this.profile.is_kyc_confirmed;
    },
    isLoading() {
      return this.loading(USER_WALLET_ADDRESS) === 'loading';
    },
    comissionsIn() {
      return this.comissions;
    },
    getRules() {
      return [
        'Минимальная разрешенная сумма для ввода и вывода - ' + this.comissionsIn.min_amount + ' ' + this.cryptoCode.toUpperCase(),
        'Постоянная комиссия на ввод - ' + this.comissionsIn.commission + ' ' + this.cryptoCode.toUpperCase()
      ];
    }
  },
  mounted() {
    this.$store.dispatch(USER_WALLET_ADDRESS, { cryptoCode: this.cryptoCode }).then(() => {
      this.address = this.userWalletAddress[this.cryptoCode];
      this.generateQRUrl();
    });
    this.sendAnalytics('Wallet', 'wallet_deposit', false);
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value).then(() => {
        this.$message({
          message: 'Адрес кошелька успешно скопирован',
          type: 'success'
        });
        this.sendAnalytics('Wallet', 'deposit_address_copy', false, ['yandex']);
      }).catch(err => {
        console.log('Ошибка при копировании адреса', err);
      });
    },
    generateQRUrl() {
      let self = this;
      QRCode.toDataURL(this.address, function(err, url) {
        if (url) {
          self.qr = url;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.kyc-no-confirmed-message {
  margin-top: 120px;
}

::v-deep.operations-tbc.operations-tbc_replenishment {
  .el-alert__description {
    font-size: inherit;
    line-height: inherit;
    margin: 5px 0;
  }
}
</style>
