<template lang="pug">
.operation-block.operation-block__withdraw(v-if="visible")
  .operation-block__data
    span
      strong Автовывод
  .operation-block__col
    p Сумма
    div {{ getUserAutoWithdrawList.amount }}
  .operation-block__col
    p Минимальный остаток
    div {{ getUserAutoWithdrawList.min_balance }}
  .operation-block__col
    p Адрес
    span {{ getUserAutoWithdrawList.wallet_id }}
  .operation-block__col
    p Тип
    span {{ getSpeed(getUserAutoWithdrawList.speed) }}
  .operation-block__to-deal
    slot(name="action")
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TheAutoWithdrawList",
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['getUserAutoWithdrawList']),
    visible() {
      return Object.keys(this.getUserAutoWithdrawList).length > 0
    }
  },
  methods: {
    getSpeed(speed) {
      const types = {
        'paid': 'Быстрый',
        'standard': 'Стандартный',
        'free': 'Бесплатный',
      }
      return types[speed];
    },
  }
}
</script>

<style scoped>

</style>
