<template lang="pug">
div
  .withdraw-commission_container
    .withdraw-switcher__container
      el-radio-group(
        v-model="mode"
        size="small"
        fill="#2464EB"
      )
        el-radio-button(label="normal" style="outline: none") Стандартный
        el-radio-button(label="fast") Быстрый
        el-radio-button(v-if='freeWithdrawals > 0' label="free") Бесплатный
    .withdraw-commission__commission(v-if='freeWithdrawals > 0')
      .title Число бесплатных выводов:
      .value {{ freeWithdrawalsEnabled ? freeWithdrawals : 'Нет ограничений' }}
      el-tooltip.item(effect="dark" :content="freeWithdrawalMessage" placement="top")
        i.withdraw-switcher__warning.el-icon-question
    .withdraw-commission__commission
      .title Комиссия:
      .value {{ commissionValue }}
    .withdraw-commission__time
      .title Время вывода:
      .value {{ withdrawTime }}
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TheWithdrawModeType",
  props: {
    commission: {
      type: null,
      required: true
    },
    cryptoCode: {
      type: String,
      default: 'btc',
      required: true
    },
    reset: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      mode: 'normal',
      freeWithdrawalMessage: 'Бесплатные выводы выдаются только администрацией',
      commissionValue: null,
    }
  },
  computed: {
    ...mapGetters([
      'getUserWalletCommissions',
      'getCommissionsCodes',
    ]),
    freeWithdrawalsEnabled() {
      return this.getUserWalletCommissions.pay_out.btc ? this.getUserWalletCommissions.pay_out.btc[0]?.counter_enabled : this.getUserWalletCommissions.pay_out[0]?.counter_enabled;
    },
    freeWithdrawals() {
      let freeWithdrawCount = this.getUserWalletCommissions.pay_out.btc
        ? this.getUserWalletCommissions.pay_out.btc[0]?.free_withdraws
        : this.getUserWalletCommissions.pay_out[0]?.free_withdraws;
      if (freeWithdrawCount < 0) {
        freeWithdrawCount = 0
      }
      return freeWithdrawCount;
    },
    withdrawTime() {
      if (this.mode === 'fast') return '~30 минут'
      if (this.mode === 'normal') return '~60 минут'
      return '60+ минут'
    },
  },
  watch: {
    getUserWalletCommissions() {
      if (this.freeWithdrawalsEnabled === true) {
        if (this.freeWithdrawals === 0 && this.mode !== 'fast') {
          this.mode = 'normal';
        }
      }
    },
    commission(newValue) {
      this.commissionValue = newValue
    },
    mode: {
      handler(newVal) {
        this.$emit('withdraw-mode-type', {mode: newVal})
      }
    },
    reset(val) {
      if (val) {
        this.mode = 'normal'
        this.$emit('withdraw-mode-type', {
          mode: this.mode
        })
      }
    }
  },
  mounted() {
    this.$emit('withdraw-mode-type', {mode: this.mode})
  }
}
</script>

<style scoped>

</style>