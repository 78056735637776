<template lang="pug">
  .select-box.currency-width(@click="toggleOpen()")
    .select-box__current.currency-width__area.create-line__item-select(
      :class="{'select-box__current-opened': isOpen}"
      :tabindex='tabIndex'
      @blur="isOpen = false",
      ref="select",
    )

      .select-box__value(v-for="item in items", :key="item.value")
        input.select-box__input(
          type='radio',
          :name="name",
          :checked="item.value === value ? 'checked' : ''",
          :id="itemHtmlId(item.value)",
          :value="item.value",
          @input="$emit('input', $event.target.value)"
        )
        .select-box__input-text
          p.currency-width__text {{ shortLabel(item.label) }}

      img.select-box__icon(src='/img/arrow-down-white.svg' alt='все варианты' aria-hidden='true')

    ul.select-box__list
      li(v-for="item in fiatList", :key="item.value")
        label.select-box__option(:for='itemHtmlId(item.value)')
          | {{item.label}}
</template>

<script>
export default {
  name: 'CurrencySelect',

  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    name: {
      type: String,
      default: ''
    },
    tabIndex: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isOpen: false
    }
  },

  computed: {
    fiatList() {
      return this.items.filter(item => {
        return item.value !== this.value;
      })
    },
  },

  methods: {
    shortLabel(title) {
      return title.length > 40 ? title.slice(0, 40) + '...' : title
    },

    itemHtmlId(id) {
      return this.name + '-' + id
    },

    toggleOpen() {
      this.isOpen = !this.isOpen
      if (!this.isOpen) {
        this.$refs.select.blur()
      }
    }
  }
}
</script>
