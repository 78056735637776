<template lang="pug">
  .row(
    v-if='preloader',
    v-loading='isLoading',
    element-loading-text='Загрузка данных...',
  )
    .col-12
      .kyc-no-confirmed-message.operations-tbc.operations-tbc_replenishment(
        v-if="mustGoThroughKyc"
        v-loading="isProfileLoading"
      )
        KycNoVerificationMessage
      .profile-container.wrapper_gray-border.operations-tbc.operations-tbc_conclusion(
        v-else
      )
        h3.operations-tbc__title Введите адрес для вывода BTC
        el-alert(
          type="warning"
          :closable="false"
          title="Выводы между кошельками Finex без комиссии"
        )
        .operations-tbc_conclusion__container
          .operations-tbc_conclusion__block.operations-tbc_conclusion__block--withdrawAddress
            .width-block.take-set-input.take-set-input_no-right-block.pr-0.input-white
              //TheWithdrawMode(
              //  :loading="isLoading"
              //  :reset='clear'
              //  :crypto-code="cryptoCode"
              //  @withdraw-mode="getWithdrawMode"
              //)
              TheWithdrawAddress(
                :crypto-id="cryptoId"
                :crypto-name="cryptoName"
                :reset='clear'
                :is-global-auto-mode="isGlobalAutoMode"
                @min-balance="getMinBalance"
                @withdraw-address="getWithdrawAddress"
                @withdraw-comment="getWithdrawComment"
              )
            TheWithdrawModeType(
              :crypto-code="cryptoCode"
              :commission="commissionValue"
              :reset='clear'
              @withdraw-mode-type="getWithdrawModeType"
            )
          TheWithdrawAmount(
            :is-global-auto-mode="isGlobalAutoMode"
            :withdraw-mode="withdrawMode"
            :crypto-code="cryptoCode"
            :is-local-wallet="isLocalWallet"
            :crypto-id="cryptoId"
            :reset='clear'
            @withdraw-amount="getWithdrawAmount"
          )
            template(v-slot:actions)
              button.btn.btn__main.w-100(v-if="checkInputComplete" type='button' disabled="true") {{ buttonText }}
              button.btn.btn__main.w-100(v-else type='button' @click="prepareWithdraw()") {{ buttonText }}
        TheAutoWithdrawList
          template(v-slot:action)
            button.button.btn.cancel-purchase.my-0(@click="confirmDeleteAutoWithdraw") Удалить
    safety(
      :show-popup="showSafetyPopup"
      @close="closeSafety"
      @code="getCode"
      :error="safety_error"
      :type="safety_type"
      @resend="resendSafety"
    )
</template>
<script>
import {mapGetters} from 'vuex';
import {
  USER_WALLET_ADDRESS,
  USER_WALLET_WITHDRAW,
  USER_BALANCE,
  USER_WALLET_COMISSIONS,
  USER_WALLET_WITHDRAW_PAID,
  USER_WALLET_WITHDRAW_STANDARD,
  USER_WALLET_WITHDRAW_AUTO,
  USER_WALLET_WITHDRAW_AUTO_LIST,
  USER_WALLET_WITHDRAW_AUTO_DELETE,
} from '@/store/actions/user';
import { USER_PROFILE } from '@/store/actions/user';
import {AD_PRICE_TICKER} from '@/store/actions/ad';
import analytics from '@/components/mixins/analytics';
import utils from '@/components/mixins/utils';
import {SECURITY_REQUEST} from "@/store/actions/security";
import errors from '@/components/mixins/errors';
import security from '@/components/mixins/security';
import Safety from "@/components/Common/Safety";
// import TheWithdrawMode from "@/components/Wallet/Withdraw/TheWithdrawMode";
import TheWithdrawAddress from "@/components/Wallet/Withdraw/TheWithdrawAddress";
import TheWithdrawModeType from "@/components/Wallet/Withdraw/TheWithdrawModeType";
import TheWithdrawAmount from "@/components/Wallet/Withdraw/TheWithdrawAmount";
import TheAutoWithdrawList from "@/components/Wallet/Withdraw/TheAutoWithdrawList";
import KycNoVerificationMessage from "@/components/AwesomeComponents/Account/Profile/KycNoVerificationMessage.vue";

export default {
  components: {
    TheAutoWithdrawList,
    TheWithdrawAmount,
    TheWithdrawModeType,
    TheWithdrawAddress,
    // TheWithdrawMode,
    Safety,
    KycNoVerificationMessage,
  },
  mixins: [
    analytics,
    utils,
    security,
    errors
  ],
  props: {
    cryptoCode: {
      type: String,
      default: 'btc'
    },
    cryptoName: {
      type: String,
      default: 'bitcoin'
    }
  },

  data() {
    return {
      globalWithdrawMode: '',
      withdrawAddress: '',
      isLocalWallet: false,
      withdrawAmount: null,
      isAmountError: false,
      isAddressError: false,
      cryptoId: 1,
      withdrawMode: '',
      google2fa_secret: '',
      otp_secret: '',
      showSafetyPopup: false,
      safety_error: '',
      safety_type: 'twoFa',
      withdrawProcessing: false,
      is_delete_auto: false,
      commissionValue: null,
      commissionIncluded: false,
      clear: false,
      min_balance: null,
      comment: '',
    };
  },
  computed: {
    ...mapGetters([
      'loading',
      'getProfile',
      'getBalance',
      'getPriceTickers'
    ]),
    isProfileLoading() {
      return this.loading(USER_PROFILE) === 'loading';
    },
    mustGoThroughKyc() {
      return this.getProfile.is_kyc_required && !this.getProfile.is_kyc_confirmed;
    },
    isLoading() {
      return this.loading(USER_WALLET_ADDRESS) === 'loading' || this.loading(USER_BALANCE) === 'loading' || this.loading(SECURITY_REQUEST) === 'loading'
    },
    preloader() {
      return this.getBalance && this.getBalance.amount.length > 0
    },
    withdrawRoute() {
      if (this.isGlobalAutoMode) return USER_WALLET_WITHDRAW_AUTO
      if (this.withdrawMode === 'fast') return USER_WALLET_WITHDRAW_PAID
      if (this.withdrawMode === 'normal') return USER_WALLET_WITHDRAW_STANDARD
      return USER_WALLET_WITHDRAW
    },
    isGlobalAutoMode() {
      return this.globalWithdrawMode === 'auto'
    },
    checkInputComplete() {
      return this.isAmountError
        || this.isAddressError
        || this.isAddressError === undefined
        || this.withdrawAmount === null
        || this.isAmountError === undefined
        || this.withdrawAddress === ''
        || this.withdrawAmount === ''
        || this.withdrawProcessing;
    },
    buttonText() {
      return this.isGlobalAutoMode ? 'Создать автовывод' : 'Вывести BTC';
    },
  },
  async mounted() {
    await this.$store.dispatch(SECURITY_REQUEST);
    if (!this.getBalance) {
      this.$store.dispatch(USER_BALANCE, { id: this.getProfile.id });
    }
    this.$store.dispatch(USER_WALLET_ADDRESS, {cryptoCode: this.cryptoCode});
    if (!this.getPriceTickers[`${this.cryptoCode}usd`]) {
      this.$store.dispatch(AD_PRICE_TICKER, {crypto: this.cryptoCode, fiat: 'usd'});
    }
    this.sendAnalytics('Wallet', 'wallet_withdraw', false);
  },

  methods: {
    getWithdrawMode({mode}) {
      this.globalWithdrawMode = mode
    },
    getMinBalance(min_balance) {
      this.min_balance = min_balance
    },
    getWithdrawAddress({address, localWallet, error}) {
      this.withdrawAddress = address
      this.isLocalWallet = localWallet
      this.isAddressError = error
    },
    getWithdrawComment(comment) {
      this.comment = comment
    },
    getWithdrawModeType({mode}) {
      this.withdrawMode = mode
    },
    getWithdrawAmount({amount, commission, included, error}) {
      this.withdrawAmount = amount
      this.commissionValue = commission
      this.commissionIncluded = included
      this.isAmountError = error
    },
    prepareWithdrawData() {
      let data = {
        wallet_id: this.withdrawAddress,
        amount: this.withdrawAmount,
        crypto_currency_id: this.cryptoId,
        commissionIncluded: this.commissionIncluded,
        min_balance: !this.min_balance ? null : Number(this.min_balance),
        comment: this.comment
      }
      if (this.isGlobalAutoMode) {
        let speed = this.withdrawMode;
        if (this.withdrawMode === 'fast') {
          speed = 'paid'
        }
        if (this.withdrawMode === 'normal') {
          speed = 'standard'
        }
        data.speed = speed
      }
      return data;
    },
    getCode({code, type}) {
      if (this.is_delete_auto) {
        if (type === 'otp') {
          this.otp_secret = code;
          this.deleteAutoWithdraw(false, true);
        } else {
          this.google2fa_secret = code;
          this.deleteAutoWithdraw(true);
        }
      } else {
        const data = this.prepareWithdrawData()
        if (type === 'otp') {
          this.otp_secret = code;
          data.otp = code;
        } else {
          this.google2fa_secret = code;
          data.google2fa_secret = code;
        }
        this.withdraw(data);
      }
    },
    prepareWithdraw() {
      const data = this.prepareWithdrawData();
      this.withdraw(data)
    },
    confirmDeleteAutoWithdraw() {
      this.$confirm(
        'Вы уверены что хотите удалить автовывод',
        'Подтверждение',
      ).then(() => {
        this.deleteAutoWithdraw();
      })
    },
    closeSafety() {
      this.showSafetyPopup = false;
      this.google2fa_secret = '';
      this.otp_secret = '';
      this.safety_error = '';
      this.is_delete_auto = false;
    },
    resendSafety() {
      this.safety_error = '';
      if (this.is_delete_auto) {
        this.deleteAutoWithdraw();
      } else {
        this.$store.dispatch(USER_WALLET_WITHDRAW, {paid: this.withdrawRoute})
      }
    },
    withdraw(data) {
      this.withdrawProcessing = true;
      this.safety_error = ''
      this.$store.dispatch(USER_WALLET_WITHDRAW, {
        data,
        paid: this.withdrawRoute,
      }).then(() => {
        this.withdrawProcessing = false;
        this.clear = true
        this.closeSafety();
        let message = '<div class="withdraw-message-success header">Вывод успешно добавлен в очередь</div>';
        message += `<div class="withdraw-message-success">Адрес: `;
        message += `<span class="withdraw-value">${data.wallet_id}</span></div>`;
        message += `<div class="withdraw-message-success">Сумма: `;
        message += `<span class="withdraw-value">${data.amount} ${this.cryptoCode}</span></div>`;
        this.$alert(message, {
          dangerouslyUseHTMLString: true,
          showClose: true,
          type: 'success'
        });
        this.sendAnalytics('Wallet', 'withdraw_success', false, ['yandex']);
        this.sendAnalytics('Wallet', 'InitiateCheckout', false, ['facebook']);
        this.$store.dispatch(USER_WALLET_WITHDRAW_AUTO_LIST, { cryptoCode: this.cryptoCode });
      }).catch(error => {
        const safety_errors = this.haveSecurityError(error);
        if (safety_errors.have) {
          if (safety_errors.need_open && this.showSafetyPopup === false) {
            this.safety_type = safety_errors.type;
            this.showSafetyPopup = true;
          } else {
            this.safety_error = safety_errors.message;
          }
        } else if (error && error.errors) {
          this.$message.error({
            dangerouslyUseHTMLString: true,
            message: this.formatErrors(error.errors),
          });
          this.clear = true
          this.closeSafety()
        } else if (error.message) {
          this.$message.error(error.message);
          this.clear = true
          this.closeSafety()
        } else {
          this.$message.error('У нас небольшой сбой с автоматическим выводом средств. Напишите в поддержку и мы быстро сделаем это вручную');
          this.clear = true
          this.closeSafety()
        }
        this.withdrawProcessing = false;
      }).finally(() => {
        this.clear = false
        this.$store.dispatch(USER_WALLET_COMISSIONS);
      });
    },
    deleteAutoWithdraw(with_2fa = false, with_otp = false) {
      this.is_delete_auto = true;
      let payload = {cryptoCode: this.cryptoCode};
      if (with_2fa) {
        payload.google2fa_secret = this.google2fa_secret;
      }
      if (with_otp) {
        payload.otp = this.otp_secret;
      }
      this.safety_error = ''
      this.$store.dispatch(USER_WALLET_WITHDRAW_AUTO_DELETE, payload)
        .then(() => {
          this.closeSafety();
          this.$store.dispatch(USER_WALLET_WITHDRAW_AUTO_LIST, {cryptoCode: this.cryptoCode});
          this.$alert('Успешно удалено', {
            showClose: true,
            type: 'success'
          });
        })
        .catch((error) => {
          const safety_errors = this.haveSecurityError(error);
          if (safety_errors.have) {
            if (safety_errors.need_open && this.showSafetyPopup === false) {
              this.safety_type = safety_errors.type;
              this.showSafetyPopup = true;
            } else {
              this.safety_error = safety_errors.message;
            }
          } else if (error && error.errors) {
            this.$message.error({
              dangerouslyUseHTMLString: true,
              message: this.formatErrors(error.errors),
            });
          } else if (error.message) {
            this.$message.error(error.message);
          }
        })
    },
  }
};
</script>
<style lang="scss">
.el-message-box__status::before {
  padding: 0;
}

.withdraw-fiat {
  font-family: 'Inter';
  font-weight: bold;
  font-size: 14px;
  padding-top: 10px;
  height: 31px;

  p {
    color: #353535;
    margin: 0;

    span {
      color: $blue600;
    }
  }


}

.withdraw-error {
  font-size: 14px;
  line-height: 20px;
  color: red;
  height: 30px;
  display: flex;
  align-items: center;
}

.checkbox-block {
  padding-top: 5px;
}

.operations-tbc_conclusion__checkbox {
  font-size: 14px;
}

.operations-tbc_conclusion__block--withdrawAddress {
  align-items: flex-start;
}

.operations-tbc_conclusion__block-btn {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 16px;
  border-radius: 5px;
  height: 47px;

  button {
    height: 100%;
  }
}

.operations-tbc_conclusion__block-text {
  justify-content: flex-start;
  margin-left: 5px;
  height: 20px;
  margin-top: 5px;

  p {
    font-size: 10px;
  }
}

.withdraw-switcher__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;

  .withdraw-switcher__warning {
    margin-right: 5px;
    cursor: pointer;
  }

  .withdraw-switcher__switcher {
    margin-left: 10px;
  }
}

.withdraw-commission_container {
  padding-left: 15px;
  font-size: 14px;

  .title {
    font-weight: bold;
    display: inline-block;
  }

  .value {
    margin-left: 5px;
    display: inline-block;
    padding-right: 10px;
  }

}

.auto-withdraw-tooltip {
  margin-left: 5px;
}

.operation-block__withdraw {
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}
.block__min-balance {
  display: inline-flex;
  width: 100%;
  padding-top: 10px;
}

.kyc-no-confirmed-message {
  margin-top: 120px;
}
</style>
