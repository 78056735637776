<template lang="pug">
  .operation-block-history
    .operation-block-history__block.operation-block-history__data
      span.operation-history__date {{ divideDate(record.date).dateDay }}
      span.operation-history__date {{ divideDate(record.date).time }}
    .operation-block-history__block.operation-block-history__description
      p Описание
      span {{ record.description }} {{ record.wallet_id }}
    .operation-block-history__block.operation-block-history__amount
      p Сумма
      span(:class="{'amount-plus': record.amount[0] === '+', 'amount-minus': record.amount[0] === '-'}") {{ record.amount }}
    .operation-block-history__block.operation-block-history__before
      p Баланс до
      span {{ record.balance_before }}
    .operation-block-history__block.operation-block-history__after
      p Баланс после
      span {{ record.balance_after }}
    .operation-block-history__block.operation-block-history__reason
      p Операция с
      span {{ tx_types[record.reason.reason] || record.reason.reason }}
</template>
<script>
import utils from '@/components/mixins/utils';

export default {
  mixins: [
    utils,
  ],
  props: {
    record: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tx_types: {
        commission: 'Комиссия',
        bot_commission: 'Комиссия',
        transfer: 'Трансфер',
        deal: 'Сделка',
        cashback: 'Кэшбек со сделок',
        commission_cashback: 'Кэшбек с комиссии',
      },
    };
  },
};
</script>
