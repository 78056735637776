<template lang="pug">
  .pt-3
    .account-balance(
    v-loading='isLoading',
    element-loading-text='Загрузка данных...',
    )
      .account-balance__main(v-if="preloader")
        .account-balance__title-block
          .account-balance__title Ваш баланс

        .account-balance__info
          span.account-balance__info-number {{ `${showBalanceIn.amount}` }}
          PaymentSelect(
            tabIndex="1",
            :items="fiatCurrencies",
            name="OfferTypeFrom",
            v-model="selectedCurrency",
          )
          .account-balance__info-btc
            p {{ `${cryptoCode} ${getBalance.amount > 0 ? getBalance.amount : '0.00000000'}` }}
        .account-balance__content
          .account-balance__content-using.account-balance__content-block
            p Доступно
            span {{ `${getBalance.due} ${cryptoCode}` }}
          .account-balance__content-escor.account-balance__content-block
            p В эскроу
            span {{ `${getBalance.escrow} ${cryptoCode}` }}
      .account-balance__btns-container
        router-link.btn.btn__ghost_light(:to="{ name: 'WhiteList' }", tag="a") Настройки
        router-link.btn.btn__ghost_light(:to="{ name: 'Deposit' }", tag="a") Пополнить
        router-link.btn.btn__ghost_light(:to="{ name: 'Withdraw' }", tag="a") Вывести
</template>
<script>
import {mapGetters} from 'vuex';
import { USER_BALANCE, UPDATE_SELECTED_CURRENCY } from '@/store/actions/user';
import PaymentSelect from './Partials/PaymentSelect';

export default {
  components: {
    PaymentSelect,
  },
  data () {
    return {
      selectedCurrency: '',
      cryptoCode: 'BTC',
    };
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
      'getBalance': 'getBalance',
      'loading': 'loading',
      'getSelectedCurrency': 'getSelectedCurrency',
    }),
    isLoading () {
      return this.loading(USER_BALANCE) === 'loading';
    },
    showBalanceIn() {
      let rate = this.getBalance.fiat.rates.find(item => {
        return item.code === this.selectedCurrency;
      });
      return {
        amount: (this.getBalance.fiat.amountUsd * rate.rate).toFixed(0),
        code: rate.code.toUpperCase()
      };
    },
    fiatCurrencies() {
      return this.getBalance.fiat.rates.map(item => {
        return {
          value: item.code,
          label: item.code.toUpperCase(),
        };
      })
    },
    preloader() {
      return this.getBalance && this.getBalance.amount.length > 0
    },
  },
  watch: {
    selectedCurrency(val) {
      if (this.getSelectedCurrency !== val) {
        this.$store.dispatch(UPDATE_SELECTED_CURRENCY, val);
      }
    },
  },
  mounted() {
    this.$store.dispatch(USER_BALANCE, {id: this.profile.id})
  },
  created() {
    this.selectedCurrency = this.getSelectedCurrency;
  }
}
</script>
<style lang="scss" scoped>
.account-balance__title-block {
  display: flex;
  padding-bottom: 20px;
}
.account-balance__wallet {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 80px;
}
.add-icon__f18 {
  font-size: 18px;
}
</style>
