<template lang="pug">
  .tabs-content.tabs-purse.wrapper_gray-border
    HistoryTabs(:isLoading="isLoading" @selectComponent="method = $event")
    .tabs
      section.content-tab(
        v-loading='isLoading',
        element-loading-text='Загрузка данных...',
      )
        section.page-info.page-info_purse
          .page-info__container(v-if="history.length > 0")
            component(:is='historyRecord' v-for="record in history", :record="record" :key="record.id")
          .page-info__container.page-info__container--empty(v-else)
            | Еще нет записей
        .deals-pagination
          el-pagination(
            v-if='hasPagination',
            :total='pages.total',
            :page-size='pages.per_page',
            :current-page='pages.current_page',
            :disabled='isLoading'
            background,
            layout='prev, pager, next',
            @current-change='changeCurrent',
        )
</template>
<script>
import { mapGetters } from 'vuex';
import {USER_WALLET_HISTORY, USER_WALLET_PAYING, USER_WALLET_PAYOUT} from '../../store/actions/user';
import HistoryRecord from '../../components/Wallet/HistoryRecord';
import PayInRecord from '../../components/Wallet/PayInRecord';
import PayOutRecord from '../../components/Wallet/PayOutRecord';
import HistoryTabs from '../../components/Wallet/Partials/HistoryTabs';
import analytics from '../../components/mixins/analytics';

export default {
  components: {
    HistoryRecord,
    PayInRecord,
    PayOutRecord,
    HistoryTabs
  },
  mixins: [analytics],
  data() {
    return {
      busy: false,
      cryptoCode: 'BTC',
      history: [],
      pages: {},
      method: USER_WALLET_HISTORY,
      historyRecord: 'HistoryRecord'
    };
  },
  computed: {
    ...mapGetters([
      'loading',
      'getUserWalletHistory',
      'getProfile'
    ]),
    isLoading() {
      return this.loading(this.method) === 'loading';
    },
    hasPagination() {
      return !!this.pages && this.pages.total_pages > 1;
    }
  },
  watch: {
    is_active() {
      this.getData();
    },
    method(){
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    changeCurrent(page) {
      this.sendAnalytics('Wallet', this.method, false);
      this.getData(page);
    },
    async getData(page) {
      await this.$store.dispatch(USER_WALLET_HISTORY, { cryptoCode: this.cryptoCode, filter: { page }, method: this.method });
      this.history = this.getUserWalletHistory.data;
      this.history.forEach(event => event.show = false);
      this.pages = this.getUserWalletHistory.pagination;
      switch (this.method) {
        case USER_WALLET_HISTORY:
          this.historyRecord = "HistoryRecord";
          break;
        case USER_WALLET_PAYOUT:
            this.historyRecord = "PayOutRecord";
            break;
        case USER_WALLET_PAYING:
            this.historyRecord = "PayInRecord";
            break;
      }
    },
  }
};
</script>
<style scoped>
  @import '../../assets/sass/history_table.scss';
  .deals-pagination {
    text-align: center;
    margin: 10px;
  }

  .tabs-content .tabs > section {
    display: block;
  }
  .page-info__container--empty{
    padding-top: 20px;
  }
</style>
